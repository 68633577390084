import { mapGetters } from "vuex";

export default {
  data() {
    return {
      courseId: "",
      blockId: ""
    };
  },
  computed: {
    ...mapGetters(["getAllLtiUnits", "error"])
  },
  created() {
    this.courseId = this.$route.params.courseId;
    this.blockId = this.$route.params.blockId;
  },
  methods: {
    listLtiUnits() {
      this.isLoading = true;
      this.$store
        .dispatch("listAllLtiUnits", {
          courseId: encodeURIComponent(this.courseId)
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    exportEnrollments(item, blockId, type) {
      type == "attendance"
        ? (item.exportStatus = "loading")
        : (this.exportStatus = "loading");

      if (blockId) {
        this.blockId = blockId;
      }
      let params = {
        courseId: encodeURIComponent(this.courseId),
        blockId: encodeURIComponent(this.blockId)
      };
      this.$store
        .dispatch("exportEnrollments", params)
        .then(() => {
          type == "attendance"
            ? (item.exportStatus = "success")
            : (this.exportStatus = "success");
          setTimeout(() => {
            type == "attendance"
              ? (item.exportStatus = "default")
              : (this.exportStatus = "default");
          }, 1000);
        })
        .catch(() => {
          type == "attendance"
            ? (item.exportStatus = "warning")
            : (this.exportStatus = "warning");
          if (type === "attendance") {
            setTimeout(() => {
              type == "attendance"
                ? (item.exportStatus = "default")
                : (this.exportStatus = "default");
            }, 1000);
          }
        });
    },
    onRowHovered(item, index) {
      this.getAllLtiUnits[index].exportStatus = "hover";
    },
    onRowUnHovered(item, index) {
      this.getAllLtiUnits[index].exportStatus = "default";
    },
    onUsersHovered(item, index) {
      this.getEnrolledUsers.results[index].isRowHovered = true;
    },
    onUsersUnhovered(item, index) {
      this.getEnrolledUsers.results[index].isRowHovered = false;
    },
    exportIcon(item) {
      if (item.exportStatus === "default") {
        return require("@/assets/images/virtual-classes/export-grey.svg");
      } else if (item.exportStatus === "hover") {
        return require("@/assets/images/virtual-classes/export-active.svg");
      } else if (item.exportStatus === "loading") {
        return require("@/assets/images/virtual-classes/export/loader.svg");
      } else if (item.exportStatus === "success") {
        return require("@/assets/images/virtual-classes/export/success.svg");
      } else if (item.exportStatus === "warning") {
        return require("@/assets/images/virtual-classes/export/warning.svg");
      }
    },
    exportBtnIcon() {
      if (this.exportStatus === "default" || this.exportStatus === "hover") {
        return require("@/assets/images/virtual-classes/export-white.svg");
      } else if (this.exportStatus === "loading") {
        return require("@/assets/images/virtual-classes/export/loader-white.svg");
      } else if (this.exportStatus === "success") {
        return require("@/assets/images/virtual-classes/export/success-white.svg");
      }
    }
  }
};
