<template>
  <div class="vc-management__header">
    <div class="vc-management__header--left">
      <f2f-breadcrumb :items="items" />
      <h3>{{ headerText }}</h3>
    </div>
    <div
      class="vc-management__header--right"
      v-if="$route.name === 'AttendanceRecord'"
    >
      <f2f-button
        :variant="exportStatus === 'warning' ? 'warning' : 'success'"
        :disabled="isExportDisabled"
        @click="exportEnrollments(exportStatus, blockId, 'record')"
        ><div class="vc-management__header--right__btn">
          <img
            :src="exportBtnIcon()"
            alt="export"
            class="mr-2"
            v-if="exportStatus !== 'warning'"
          />
          <span
            v-if="exportStatus !== 'loading' && exportStatus !== 'success'"
            >{{ exportStatus === "warning" ? "Try again" : "Export" }}</span
          >
        </div></f2f-button
      >
    </div>
  </div>
</template>
<script>
import VirtualClassMixin from "../mixin";
export default {
  props: {
    currentUnit: {
      type: Object
    },
    isExportDisabled: Boolean
  },
  mixins: [VirtualClassMixin],
  data() {
    return {
      exportStatus: "default"
    };
  },
  computed: {
    items() {
      let arr = [];
      if (this.$route.name === "Management") {
        arr = [
          {
            text: "Tools"
          },
          {
            text: ""
          }
        ];
      } else if (this.$route.name === "AttendanceRecord") {
        arr = [
          {
            text: "Tools"
          },
          {
            text: "Attendance",
            to: { name: "Management" }
          },
          {
            text: this.$route.query.name
          }
        ];
      }
      return arr;
    },
    headerText() {
      let text;
      if (this.$route.name === "Management") {
        text = "Attendance";
      } else if (this.$route.name === "AttendanceRecord") {
        text = "Attendance Record";
      }
      return text;
    }
  }
};
</script>
<style lang="scss" scoped>
.vc-management__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 24px;
  }
  .vc-management__header--right {
    .vc-management__header--right__btn {
      @include horizontal-center;
      font-weight: 500;
      min-width: 85px;
    }
  }
}
</style>
