<template>
  <div class="vc-empty__component">
    <div>
      <p v-if="componentType === 'record'">
        There are no students enrolled in this program.<br />
        Here you will be able to mark attendance for specific students.
      </p>
      <p v-else>
        There are no Virtual classrooms yet.<br />
        Add zoom classes to your units and track attendance here.
      </p>
    </div>
    <f2f-button
      variant="outline-primary"
      text="Back to content outline"
      @click="onBackHome"
    ></f2f-button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    componentType: String
  },
  computed: {
    ...mapGetters(["allConfig"])
  },
  methods: {
    onBackHome() {
      window.location.href = `${this.allConfig.studioBaseURL}/course/${this.$route.params.courseId}`;
    }
  }
};
</script>
<style lang="scss">
.vc-empty__component {
  @include center;
  height: 250px;
  p {
    @include p;
  }
}
</style>
