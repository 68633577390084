<template>
  <div class="vc__access-denied">
    <img
      src="@/assets/images/virtual-classes/access-denied.svg"
      alt="access denied"
    />
    <h5>Access Denied</h5>
    <p>
      You currently do not have access to this page. Please try again later.
    </p>
    <f2f-button
      variant="outline-primary"
      text="Back to Home"
      @click="onBackHome"
    ></f2f-button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["allConfig"])
  },
  methods: {
    onBackHome() {
      window.location.href = `${this.allConfig.studioBaseURL}/home`;
    }
  }
};
</script>
